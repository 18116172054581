#footer {
	background-color: $second-color;
	color: white;
	padding: 20px 0;
	font-size: 15px;
	float: left;
	width: 100%;

	p {
		font-size: 15px;
		line-height: 22px;
		color: white;
	}

	a {
		color: white;

		&:hover {
			color: white;
		}
	}

	h3 {
		text-transform: uppercase;
		border-bottom: 1px solid white;
		margin-bottom: 15px;
		padding-bottom: 6px;
		line-height: 20px;
	}

	.footer {
		> table {
			width: calc(100% - 200px);
			float: left;

			@media screen and (max-width: $tablet) {
				width: 100%;
			}

			td {
				@media screen and (max-width: $tablet) {
					width: 100%;
					float: left;
				}
			}
		}

		.footer-counter {
			float: left;
			width: 200px;

			@media screen and (max-width: $tablet) {
				width: 100%;
			}

			.total_top {
				display: none;
			}

			table {
				width: 100%;

				tbody {
					tr {
						line-height: 22px;

						th {
							font-weight: normal;
						}

						td {
							float: right;
							text-align: right;
						}
					}
				}
			}
		}

		.socials {
			float: left;
			width: 100%;
			margin-top: 10px;
		}
	}
}