.contact {
	// Sửa lỗi padding Recaptcha che mất đối tượng khác
	.embed-responsive-16by9 {
		padding-bottom: 25.25% !important;
	}

	.btn-default {
		@media screen and (min-width: $tablet + 1px) {
			margin-top: 20px;
		}
	}
}