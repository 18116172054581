#widget_contact {
  position: fixed;
  bottom: 6.6rem;
  right: 1rem;
  float: left;
  width: 50px;
  z-index: 66;

  // @media screen and (max-width: 991px){
  //   width: 100%;
  //   bottom: 0;
  //   left:0;
  //   text-align: center;
  // }

  .icon-phone {
    background: red;
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border-radius: 9999px;
  }

  .icon-facebook {
    background:#4267b2;
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border-radius: 9999px;
  }

  .icon-lienhe {
    background:#4267b2;
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border-radius: 9999px;
  }

  .navbar-collapse {
      webkit-transition: .3s ease-out;
      transition: .3s ease-out;

    .show {

      webkit-transition: .3s ease-out;
      transition: .3s ease-out;
    }
  }
  .support {
    .icon-support {
      transform: rotate(45deg);
      webkit-transition: .3s ease-out;
      transition: .3s ease-out;
      cursor: pointer;
    }

    &.collapsed {

      .icon-support {
        transform: rotate(0deg);
        -webkit-transition: .3s ease-out;
        transition: .3s ease-out;
      }
    }
  }

  .icon-msg {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -214px -39px;
  }
  .icon-zalo {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -270px -39px;
  }
  .icon-wechat {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -272px -93px;
  }

  .fa-skype {
    border-radius: 50%;
    text-align: center;
    width: 43px;
    height: 43px;
    line-height: 43px;
    background-color: #02aff0;
    font-size: 1.7rem;

    &:hover {
      text-decoration: none;
    }
  }

  .icon-whatsapp {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -215px -93px;
  }
  .icon-fb {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -215px -39px;
  }
  .icon-support {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -156px -40px;
  }
  .icon-hotline {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -325px -39px;
  }

  .zaloSF {
    position: relative;
  }

  >ul {
   > li {
    >  a {
        width: 43px;
        height: 43px;
        border-radius: 100%;
        color: #ffff;
        display: block;
        text-align: center;
        -webkit-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        position: relative;
      }

      &.backSF a {
          background-color: #2653b0;
          line-height: 39px;
      }
    }
  }
  // @media (max-width: 991px) {
  //   ul {
  //     li {
  //       width: 25%;
  //       float: left;
  //       height: 43px;
  //       text-align: center;

  //       a {
  //         margin: 0 auto;
  //       }

  //     }
  //   }
  // }
}

.icon-map-maker {
  display: inline-block;
  width: 43px;
  height: 43px;
  vertical-align: text-top;
  line-height: 48px;
}

.support-content {
  width: 215px;
  position: absolute;
  background: #fff;
  top: 52%;
  right: 0;
  color: #000;
  text-align: left;
  line-height: 25px;
  border: solid 2px #00acd4;
  border-radius: 5px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -mos-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  visibility: hidden;
  opacity: 0;
  z-index: 11;
  @media (max-width: 991px) {
    top: 30%;
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #00acd4;
    position: absolute;
    left: 100%;
    top: 20px;

    // @media (max-width: 991px) {
    //   top: initial;
    //   bottom: -20px;
    //   border-right: 10px solid transparent;
    //   border-left: 10px solid transparent;
    //   border-top: 10px solid #00acd4;
    //   left: 65%;
    // }
  }
  &.show {
    opacity: 1;
    visibility: initial;
    right: 65px;

    // @media (max-width: 991px) {
    //   left: 50%;
    //   transform: translateX(-50%);
    //   right: initial;
    // }
  }

  .title {
    text-align: center;
    font-size: 13px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px 5px 0 0;
  }

  .line {
    padding: 5px 20px;
    width: 100%;
    float: left;
    margin: 0;

    @media (max-width: 991px) {
      padding: 2px 20px;
    }

    span {
      display: block;
    }

    a {
      display: block;
      position: relative;
      color: #00acd4;
      font-weight: bold;
      line-height: 28px;
      font-size: 16px;
      width: 100%;
      align-items: center;
      display: inline-flex;

      @media (max-width: 991px) {
        font-size: 14px;
      }

      img {
        margin-right: 5px;
      }
    }
  }

  p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
}