$primary-color: #01923f;
$second-color: #01b64e;
$third-color: #00c0ab;
$fourth-color: #e32a2d;
$divider-color: #ddd;
$text-color: #414141;
$price-new-color: #e32a2d;
$right-boxes-space: 30px;
$link-color: #0155c7;
$link-hover-color: #0155c7;
$mobile: 576px;
$tablet: 768px;

* {
  outline: none;
  font-family: Arial, sans-serif;
}

@import 'old-frontend';
@import 'override_bootstrap';
@import 'flash_message';
@import 'global';
@import 'header';
@import 'nav';
@import 'footer';
@import 'home';
@import 'product';
@import 'contact';
@import 'category';
@import 'post';
@import 'counter';
@import 'comment';
@import 'applicant';
@import 'information';
@import 'smooth_product';
@import 'sidebar';
@import 'slideshow';
@import 'order';
@import 'widget_contact';
