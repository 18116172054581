#product-list {
	header {
		.title {
			margin-bottom: 10px;
		}
	}

	.thumb {
		text-align: center;
		border: 1px solid $primary-color;
		border-radius: 4px;

		img{
			border-radius: 4px;
		}
	}
}

.product-view h1{
	color: $link-color;
}

.border_bottom_desc{
	border-bottom: 1px solid $primary-color;
}

.icon_li_cate {
		width: 17px;
		height: 25px;
		background-position: -272px -61px;
}

.product_in_stock{
	color: $primary-color;
}

ul.social li {
	margin-left: 5px;
}

.product_empty{
	color: red;
}

.product-grid-item {
	margin-bottom: 30px;

	.thumb {
		text-align: center;
		border: 1px solid $primary-color;
	}

	.name {
		padding: 0;
		margin: 6px 0;
		border-bottom: 1px solid white;
		font-weight: bold;

		a{
			color: $text-color;
		}
	}

	.infos {
		list-style: none;
		margin: 0;
		padding: 0;
		margin-bottom: 10px;
		float: left;
		width: 100%;

		li {
			float: left;
			width: 100%;

			.detail {
				float: right;

				.glyphicon-chevron-right {
					color: $second-color;
					font-size: 8px;
					vertical-align: top;
					margin-top: 5px;
				}
			}

			.price {
				font-size: 14px;

				&.old {
					color: #a0a0a0;
				}

				&.new {
					font-weight: bold;
					color: #e32a2d;
				}

				&.call {
					margin-top: 14px;
					float: left;
					font-size: 13px;
				}
			}

			.contact-link {
				&:hover {
					color: $price-new-color;
				}
			}

			.price-wrapper {
				text-align: right;
				float: left;
				margin: 0;
				height: 38px;
			}

			.add-to-cart {
				margin: 9px 0 0 0;
				float: right;

				.btn {
					padding: 5px 7px;
				}
			}
		}

	}
}

.icon_new {
	width: 48px;
	height: 28px;
	position: absolute;
	z-index: 9;
	left: 25px;
	top: 10px;
	background-position: -187px -155px;
}

.icon_hot {
	width: 48px;
	height: 28px;
	z-index: 9;
	position: absolute;
	left: 25px;
	top: 10px;
	background-position: -187px -116px;
}

.detail_product {
	.product-view {
		header {
			h1 {
				color: $fourth-color;
			}
		}
		.images {
			@media screen and (min-width: $tablet) {
				padding: 0;
			}
		}

		.summary {
			width: 100%;
			float: left;
			border: 1px dashed $primary-color;
			padding: 20px;
			box-sizing: border-box;
			margin: 10px 0;
		}

		.order-wrapper {
			margin-top: 0;
			float: left;
			width: 100%;

			.order-label {
				float: left;
				line-height: 28px;
			}

			#qty-order {
				float: left;
				margin-left: 10px;
				width: 60px;
				height: 28px;
				padding: 4px 12px;
			}

			#add-to-cart-btn {
				float: left;
				margin-left: 10px;
				padding: 4px 12px 4px 38px;
				background-color: $primary-color !important;
				border: none;
				color: white !important;
				text-shadow: none;
				outline: none !important;
				background-image: url('img/icon.png') !important;
				background-position: -315px -82px;
				background-repeat: no-repeat;
				font-size: 13px;

				&:hover {
					background-image: url('img/icon.png') !important;
					background-position: -315px -82px;
					background-repeat: no-repeat;
					background-color: darken($primary-color, 5) !important;
				}
			}
		}

		.socials {
			text-align: right;
			margin-top: 15px;
			padding: 0;

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					float: left;
					margin-right: 10px;
				}
			}
		}

		.related {
			.name {
				color: $text-color;

				&:hover {
					color: $text-color;
				}
			}

			.infos {
				display: none;
			}
		}

		.product_des {
			img {
				@media screen and (max-width: $tablet) {
					max-width: 100%;
					height: auto !important;
				}
			}
		}
	}
}