.read {
	header.title {
		margin-bottom: 10px;
	}
}

.related {
	.title {
		font-weight: bold;
		margin: 0 0 10px 0;
	}

	#show_post_related {
		float: left;

		ul {
			float: left;
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				padding: 0 0 0 17px;
				background: url('img/icon.png') -254px -89px no-repeat;
				margin-bottom: 2px;
			}
		}
	}
}

.list_post {
	.thumb {
		padding: 0;
	}

	header {
		.title {
			border-bottom: 1px solid $divider-color;
		}
	}

	.name {
		margin-bottom: 3px;
	}
}