body {
	.border_bottom_ccc {
		border-bottom: 1px solid #ccc;
	}

	.line_height30 {
		line-height: 30px;
	}

	.owl-theme {
		.owl-dots {
			.owl-dot {
				&.active {
					span {
						background-color: $second-color;
					}
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: -78px;
			left: 0;
			width: 100%;
			display: block !important;

			@media screen and (max-width: $mobile) {
				top: calc(50% - 50px);
			}

			.owl-prev {
				background: #ECECEC;
				position: absolute;
				right: 115px;

				@media screen and (max-width: $mobile) {
					left: 0;
					right: auto;
				}

				&:hover {
					background: #ECECEC;
				}

				.glyphicon {
					color: #777;
				}

				.owl-left {
					background: url('img/owl-left.png');
					background-repeat: no-repeat;
					width: 35px;
					height: 40px;
					background-size: 13px auto;
					background-position: 12px 0px;
				}
			}

			.owl-next {
				background: #ECECEC;
				position: absolute;
				right: 80px;

				@media screen and (max-width: $mobile) {
					right: 0;
				}

				&:hover {
					background: #ECECEC;
				}

				.glyphicon {
					color: #777;
				}

				.owl-right {
					background: url('img/owl-right.png');
					background-repeat: no-repeat;
					width: 35px;
					height: 40px;
					background-size: 13px auto;
					background-position: 12px 0px;
				}
			}
		}
	}

	.container {
		@media (min-width: 1200px) {
			width: 1200px;
		}
	}

	#wrapper {
		#main {
			background-color: #fff;

			#content {
				min-height: calc(100vh - 312px);
				background-color: white;

				.title-page {
					margin: 0 0 10px 0;
				}
			}
		}

		&.home {
			#content {
				padding-top: 10px;
			}
		}
	}

	a {
		color: $link-color;

		&:hover {
			color: $link-hover-color;
		}
	}

	#sidebar_left {
		@media screen and (max-width: $mobile) {
			display: none;
		}
	}
}