
.navbar {
	background-color: $primary-color;
	border: none;

	.navbar-header {
		.navbar-toggle {
			.icon-bar {
				background-color: white;
			}
		}

		.mm_search{
			margin-top: 8px;
		}
	}

	.navbar-nav {
		> li {
			background-color: $primary-color;

			&:first-child {
				a {
					//margin-left: 0;
				}
			}

			a {
				color: white;
				line-height: 26px;
				text-shadow: none;
				word-spacing: 0.6px;
				font-size: 12px;
				background-image: none;
				background-color: $primary-color;

				&.current,
				&:hover {
					background-color: $second-color;
				}
			}
			
			.arrow_right{
				position: absolute;
			    top: 11px;
			    right: 5px;
			}

			&.current,
			&:hover {
				background-color: $second-color;

				> a {
					background-color: $second-color;
				}
			}

			&.dropdown {
				.dropdown-menu {
					color: white;
					border-radius: 0;
					padding: 0;
					margin: 0;
					border: none;

					li {
						background-color: $primary-color;
						border-radius: 0;

						&:hover {
							background-color: $second-color;
						}
						
					}
				}
			}
		}

		li.last {
			line-height: 24px;
			margin: 5px 15px;

			a{
				background-color: #fff;
				border-radius: 3px;
				border: 1px solid #ccc;
				float: left;
				margin: 1px 0;
				padding: 0;
				height: 28px;
				width: 30px;

				&:hover{
					background-color: #fff!important;
				}
			}

			.icon_search{
				width: 100%;
				height: 100%;
				background-position: -169px -40px;
			}
			
			#h_search {
				padding-top: 10px;
				padding-bottom: 10px;
				width: 400px;
				z-index: 99;
				background-color: $primary-color;
				border-radius: 0 0 10px 10px;
				top: 35px;
				border: none;
			}
		}
	}
	
	.mm_search {
		.btn_msearch{
			background-image: url(img/icon.png);
			background-position: -167px -65px;
			background-color: darken($primary-color,10);
			height: 35px;
			width: 35px;
		}
	}
}