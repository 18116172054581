.owl-product {
	margin-top: 15px;
	float: left;
	width: 100%;

	.item {
		text-align: center;

		.name {
			color: $text-color;
			font-weight: bold;
		}
	}
}

// Danh mục sản phẩm trên trang chủ
#home_product_category{
	h5{
		a{
			color: $text-color;
			font-weight: 600;
		    font-size: 13px;
		}
		
	}
	
	.border_category{
		border: 1px solid $primary-color;
		border-radius: 4px;
	
		img{
			max-width: 100%;
			height: auto;
		}
	}
	
	.sub_category {
		a{
			font-size: 12px;
	    	line-height: 24px;
		}
	}
}

// Tin tức trên trang chủ dạng grid
#home_post_category{
	.title{
		border-bottom: 2px solid $primary-color;

		h2{
			a{
				color: $primary-color;
			}
		}
	}
	
	.thumb {
		
		@media (min-width: 768){
			width: 136px;
		}
		
		img{
			border: 1px solid #ccc;
		}
	}

	a{
		color: $text-color;
	}
}
// Đối tác khách hàng
#our-customer {
	.customer-title {
		text-align: center;
		color: $text-color;
		text-transform: uppercase;
		border-bottom: 2px solid $primary-color;
		font-size: 16px;
		font-weight: bold;
		padding: 0 0 6px 0
	}
}

.box_banner {
	margin-bottom: 10px;
	position: relative;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		overflow: hidden;
		padding: 7px 15px;
		height: 110px !important;

		li {
			float: left;
			border: 1px solid #fff; /* $divider-color*/
			margin: 0;
			margin-right: 21px;
    		box-shadow: 0 0 8px #ccc;
		}
	}

	.btn-prev {
		background: url('img/owl-left.png');
		background-repeat: no-repeat;
		width: 35px;
		height: 40px;
		background-size: 13px auto;
		background-position: 12px 0px;
		position: absolute;
		left: -15px;
		top: 32%;
		cursor: pointer;
	}

	.btn-next {
		background: url('img/owl-right.png');
		background-repeat: no-repeat;
		width: 35px;
		height: 40px;
		background-size: 13px auto;
		background-position: 12px 0px;
		position: absolute;
		right: -15px;
		top: 32%;
		cursor: pointer;
	}

	.caroufredsel_wrapper {
		width: 100% !important;
	}
}
