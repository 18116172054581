.detail_infomation {
	.box_content.read {
		.title {
			border-bottom: 1px solid $divider-color;
		}

		div,
		img {
			@media screen and (max-width: $tablet) {
				max-width: 100%;
				height: auto !important;
			}
		}
	}

	.related {
		ul {
			margin: 0;
			padding: 0 12px;
		}
	}
}