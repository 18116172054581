.support_text_color{
	color: $primary-color;
}
aside.box {
	border: 1px solid $primary-color;
	float: left;
	margin-bottom: 20px;
	width: 100%;
	border-radius: 4px;

	.title {
		border: none;
		background-color: $primary-color;
		color: white;
		padding: 0 15px;
		box-sizing: border-box;
		font-size: 14px;
		text-transform: uppercase;

		a {
			color: white;
		}
	}

	.readmore {
		padding: 10px;
		text-decoration: underline;
		display: none;
	}

	&.support {
		.supporters {
			float: left;
			width: 100%;
			margin: 0 0 15px 0;

			p {
				margin-bottom: 0;
			}

			.s_sub_support {
				padding: 10px 10px 0 10px;
			}

			#s_hotline {
				.company-email {
					font-weight: bold;
				}
			}
		}
	}

	&.s_post {

		.marquee {
			float: left;
			width: 100%;
			overflow: hidden;
			border-radius: 5px;
			position: relative;
			height: 200px !important;

			.js-marquee {
				margin-bottom: 0 !important;
			}
		}

		.post-thumb-list {
			float: left;
			width: 100%;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				background: white;
				width: 100%;
				float: left;
				padding: 5px;
				padding-left: 5px;
				// border-bottom: 1px solid #efefef;
				position: relative;

				a {
					color: $text-color;

					.thumb {
						float: left;

						img {
							margin-right:: 10px;
							border: 1px solid #ccc;
						}
					}

					.name {
						float: left;
						width: calc(100% - 94px);
					}
				}
			}
		}
	}

	&.product-related {
		.product-list {
			float: left;
			width: 100%;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				background: white;
				width: 100%;
				float: left;
				padding: 5px;
				padding-left: 5px;
				// border-bottom: 1px solid #efefef;
				position: relative;

				a {
					color: $text-color;

					.thumb {
						float: left;

						img {
							margin-right:: 10px;
							border: 1px solid #ccc;
						}
					}

					.name {
						float: left;
						width: calc(100% - 94px);
					}
				}
			}
		}
	}

	&.likebox {
		.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
			width: 100% !important;
		}
	}

	&.adv_left_out {
		ul {
			list-style: none;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0;
		}
	}
}

.block_title {
	border: none;

	h2 {
		background-color: $second-color;
		color: white;
		padding: 0 15px;
		box-sizing: border-box;

		.glyphicon {
			margin-right: 10px;
		}
	}
}

#tree {
	list-style: none;
	float: left;
	width: 100%;

	>li {
		float: left;
		width: 100%;
		line-height: 30px;
		padding: 0 10px;
		border-bottom: 1px solid $divider-color;

		&.current{
			>a{
				color: $link-color;
			}
		}

		&:last-child {
			border-bottom: none;
		}

		a {
			font-weight: bold;
			color: $text-color;
		}

		.s_sub_menu {
			display: none;

			li{
				padding-left: 25px;

				&.current{
					a{
						color: $link-color;
					}
				}
			}
		}

		&.current{
			.s_sub_menu {
				display: block;
			}
		}
	}
}