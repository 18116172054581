#header_top {
	display: none;
}

#header {
	padding: 0;

	.logo-desktop {
		margin: 10px 0;

		@media screen and (max-width: $tablet) {
			text-align: center;
		}
	}

	#logo {
		@media screen and (max-width: $tablet) {
			float: none;
		}

		@media screen and (min-width: $tablet) {
			margin-top: 0;
		}

		&.logo-mobile {
			@media screen and (min-width: $tablet + 1px) {
				display: none;
			}
		}

		img {
			max-height: 80px;

			@media screen and (max-width: $tablet) {
				max-height: 50px;
			}
		}
	}

	.qc-wrapper {
		float: left;
		width: 100%;
		padding-left: 77px;
	}

	.top_qc{
		margin: 21px 30px 0 30px;

		.icon_oneweb{
			width: 35px;
			height: 35px;
			margin-right: 10px;
		}

		.icon_h_price {
				background-position: -8px -123px;

		}

		.icon_h_product {
				background-position: -60px -123px;

		}

		.icon_h_transport {
			background-position: -112px -123px;
			width: 43px;
			height: 37px;
		}

		.icon_h_solution {
				background-position: -9px -174px;

		}

		&.hotline {
			@media screen and (min-width: $tablet + 1px) {
				margin-top: 28px;
			}

			.icon_hotline {
				background-position: -320px -39px;
				width: 35px;
					height: 35px;
				margin-right: 0;
			}

			.text_hotline {
				width: 137px;
				height: 36px;
				border-bottom: 1px solid #6a451a;
				margin: -7px 0 0 -4px;
				padding: 0 0 0 9px;
				line-height: 17px;

				span {
					font-size: 12px;
					display: inline-block;
					color: #df1501;
					font-weight: bold;
				}

				a {
					font-size: 19px;
					font-weight: bold;
					color: #df1501;
					display: inline-block;
					clear: left;
				}
			}
		}
	}

	.top-banner {
		@media screen and (max-width: $tablet) {
			display: none;
		}
	}

	.searchbox-wrapper {
		text-align: right;
	}

	.mobile {
		background: url('../template1/img/telephone.png') 0 0 no-repeat;
		margin: 0;
		padding: 0 0 0 45px;
		font-weight: bold;
		font-size: 14px;
		display: inline-block;

		a {
			color: $second-color;

			&:hover {
				text-decoration: none;
			}
		}

		@media screen and (min-width: $tablet + 1px) {
			font-size: 17px;
		}

		.s247 {
			float: left;
			font-weight: bold;
			font-size: 13px;
			border-bottom: 1px solid $divider-color;
			margin-bottom: 6px;
		}

		.tel {
			float: left;
			clear: left;
		}
	}

	.search {
		width: 300px;
		margin: 0 0 0 100px;
		padding: 0;
		border: 2px solid $primary-color;
		background-color: white;
		display: inline-block;
		vertical-align: top;

		@media screen and (max-width: $tablet) {
			display: none;
		}

		input {
			background: none;
			border: none;
		}

		ul {
			margin: 0;
			padding: 0;
			float: left;
			list-style: none;
			width: 100%;

			li {
				float: left;

				&.input_padding {
					width: calc(100% - 31px);

					input {
						font-size: 13px;
						width: 100%;
						float: left;
						padding: 0 8px;
						height: 30px;
					}

					@media screen and (max-width: $mobile) {
						width: 158px;
					}
				}

				&.find_header {
					background-color: $primary-color;

					.submit {
						border: none;
						background: none;

						.glyphicon {
							color: white;
							font-size: 15px;
							line-height: 30px;
							vertical-align: top;
						}
					}
				}
			}
		}
	}
}