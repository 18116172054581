.order {
	.col-gray {
		border: 1px solid $divider-color;
		height: 100%;
		box-sizing: border-box;
		border-radius: 5px;
		float: left;
		width: 100%;

		@media screen and (max-width: $tablet) {
			margin-bottom: 15px;
		}

		.title {
			float: left;
			width: 100%;
			padding: 0 10px;
			background-color: #e8e8e8;
			border-bottom: 1px solid $divider-color;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;

			h2 {
				text-transform: uppercase;
				font-size: 16px;
			}
		}

		.content {
			float: left;
			width: 100%;
			padding: 10px;
		}
	}

	.i-agree {
		#OrderAgreePolicy {
			display: inline-block;
			vertical-align: top;
		}

		label {
			width: calc(100% - 25px);
			margin-left: 8px;
			vertical-align: top;
			font-weight: normal;
		}
	}

	.order_info_wrapper {
		text-align: center;
		margin-bottom: 15px;

		.order_info {
			table {
				tr {
					&.total.vat {
						th,
						td {
							color: $text-color;
							font-weight: normal;
						}
					}
				}
			}
		}
	}

	/*** ..order_info ***/
	.order_info{
		display: inline-block;
		width: 100%;
		border: 1px solid $divider-color;
		margin-right: 1px;
		font-size: 12px;
		overflow: hidden;

		.input-qty {
			width: 65px;
			display: inline;
		}
	}
	.order_info span.title{
		background: #333;
		color: #fff;
		font-weight: bold;
		display: block;
		text-align: center;
		font-size: 13px;
		line-height: 25px;
	}
	.order_info table{
		width: 100%;
	}
	.order_info table th{
		padding: 5px 0;
		text-align: left;
		background: #f5f5f5
	}
	.order_info table th{
		border-right: 1px solid #eee;
		border-bottom: 1px solid #eee;
		padding: 5px;
	}
	.order_info table .name{
		font-weight: bold;
	}
	.order_info table .status{
		color: green;
	}
	.order_info table .promotion{
		color: #ff0000;
		font-style: italic;
	}
	.order_info table .old{
		text-decoration: line-through;
		color: #555;
	}
	.order_info table .new{
		font-weight: bold;
	}
	.order_info table .price,.order_info table .qty{
		font-weight: bold;
		font-size: 14px;
	}

	.order_info table .discount{
		color: #ff0000;
		font-style: italic;
	}
	.order_info table .center{
		text-align: center;
	}

	.order_info table td{
		padding: 0 5px;
		border-bottom: 1px solid #eee;
		border-right: 1px solid #eee;
	}
	.order_info table th:last-of-type,
	.order_info table td:last-of-type{
		border-right: none;
	}
	.order_info table tr.total th{
		text-align: right;
		background: none;
	}
	.order_info table tr.total th,
	.order_info table tr.total td{
		border-top: 1px dotted #aaa;
		font-weight: bold;
		font-size: 14px;
		line-height: 22px;
		color: #ff0000;
		white-space: nowrap;
		border: none;
	}
}

.g-recaptcha{
	.embed-responsive-16by9 {
			padding-bottom: 25.25%;
	}
}
