#applicant {
	margin: 30px 0 0 0;
	float: left;
	width: 100%;

	.title {
		text-transform: uppercase;
		font-weight: bold;
		text-align: center;
		margin-bottom: 10px;
		color: $primary-color;
	}

	#form_applican {
		border: 1px dashed $primary-color;
		padding: 20px 30px;

		#ApplicantBirthdayDay,
		#ApplicantBirthdayMonth,
		#ApplicantBirthdayYear {
			width: auto;
			display: inline-block;
		}
	}
}