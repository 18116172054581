#slideshow-wrapper {
	float: left;
	width: 100%;
	background-color: #fff;

	#slideshow {
		@media screen and (max-width: $tablet) {
			display: none;
		}
	}
}

.lSSlideOuter {
	position: relative;

	.lSPager.lSpg {
		position: absolute;
		bottom: 15px;
		left: calc(50% - 15px);

		> li {
			a {
				background-color: $second-color !important;
			}
		}

		> li.hover a,
		> li.active a {
			background-color: $primary-color !important;
		}
	}
}