@charset "utf-8";
/*************** GENERAL CSS ****************/
body {
	line-height: 20px;
	font-size: 12px;
	margin: 0;
	padding: 0;
}

h1,h2,h3,h4,h5,h6 {
	padding: 0;
	margin: 0;
}

h1 {
	font-size: 24px;
}

h2 {
	line-height: 35px;
	font-size: 19px;
}

h3 {
	font-size: 15px;
}

h4,h5,h6 {
	font-size: 14px;
}
.im{
	color: red;
}
img {
	border: 0px;
	outline: none;
}

.block_title h2{
	line-height: 35px;
		font-size: 14px;
		text-transform: uppercase;
/*     font-weight: bold; */
}
.block_title h2 a:hover{
	text-decoration: none;
	color: $second-color;
}
a:focus {
		outline: none;
}
blockquote,q {
	quotes: none;
}

blockquote:before,blockquote:after,q:before,q:after {
	content: none;
}
.highlight{
	background-color: yellow;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.clear {
	clear: both;
}

.clearL {
	clear: left;
}

.clearR {
	clear: right;
}
.icon_oneweb{
	background-image: url('img/icon.png');
	float: left;
}
/* margin custom */
.m-t-15{
	margin-top: 15px;
}
.m-b-15{
	margin-bottom: 15px;
}
.m-y-15{
	margin-bottom: 15px;
	margin-top: 15px;
}
.m-x-15{
	margin-left: 15px;
	margin-right: 15px;
}
/* padding custom */
.p-r-15{
	padding-right: 15px;
}
.p-t-15{
	padding-top: 15px;
}
.p-b-15{
	padding-bottom: 15px;
}
.p-y-15{
	padding-bottom: 15px;
	padding-top: 15px;
}
.p-x-15{
	padding-right: 15px;
	padding-left: 15px;
}

.icon-oneweb{
	background-image: url('img/icon.png');
	float: left;
}
/**** paginator ****/
.paginator {
	height: 40px;
	line-height: 20px;
	display: block;
	padding-top: 10px;
}

.paginator span {
	margin: 1px;
}
.paginator span a{
	color: #333;
	padding: 2px 5px;
		border: 1px solid #ccc;
}
.paginator span.current,#content .paginator a:hover {
	font-weight: bold;
	padding: 2px 5px;
	border: 1px solid #ccc;
	color: #cf0000;
}

.paginator a.disabled-page {
	color: #777;
}

.paginator a.disabled-page:hover {
	text-decoration: none;
	cursor: default;
}

/***** #back-top  ****/
#back-top {
	bottom: -9px;
	right: 15px;
	position: fixed;
	z-index: 9999;
}

#back-top a {
	color: #BBBBBB;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 26px;
	display: block;
	text-align: center;
}

#back-top a:hover {
	color: #000000;
}

#back-top span {
		background: darken($primary-color, 10)  url("img/up-arrow.png") no-repeat scroll center center;
		border-radius: 5px;
		width: 45px;
		height: 45px;
		display: block;
		margin-bottom: 7px;
}

#back-top a:hover span {
		background-color: darken($primary-color, 5) ;
}

.design {
	color: initial;
}

.design span {
	color: initial;
	font-weight: bold;
}

.design span span {
	color: #F6D901;
}
/**** #ajax_loading ****/
#message_top {
	display: none;
	top: 20px;
	float: right;
	position: fixed;
	z-index: 100000;
	width: 100%;
	text-align: center;
}
#message_top #loading{
	width: 160px;
	margin: 0 auto;
	padding: 30px 10px 15px;
	border-radius: 3px;
	box-shadow: 0px 0px 4px #535353;
	opacity: 0.9;
	background: #fff url(img/ajax-loading.gif) center 10px no-repeat;
}
.float_left {
	float: left;
}
.float_right {
	float: right;
}
.inline {
	display: inline;
}
.inline_block {
	display: inline-block;
}
/*************** END GENERAL CSS ****************/
/* HEADER */
#header {
		position: relative;
		z-index: 999;
}
#logo{
	float: left;
}
#language .icon_oneweb{
	height: 22px;
	width: 29px;
	float: left;
}
#language .es{
	background-position: 0 2px;
}
#language .en{
	background-position: -40px 2px;
}
#language .pt{
	background-position: -81px 2px;
}
#language .vi{
	background-position: -120px 2px;
}
#language .ko{
	background-position: -196px 2px;
}
#language .zh{
	background-position: -158px 2px;
}
div#language {
		margin: 5px 0;
}
.nav-top ul {
		list-style: none;
		margin: 5px 0;
	padding: 0;
}
.nav-top ul li {
		display: inline-block;
}
.nav-top ul li a{
	font-size: 13px;
	color: #333;
}
.top_social a,.top_social a span{
	font-size: 13px;
		font-weight: bold;
}
.li_item{
	float:left;
		margin: 7px 5px 23px 0;
	width: 5px;
	height: 5px;
	background-color: $second-color;
}
.icon_facebook {
		width: 16px;
		height: 16px;
	margin-right: 5px;
		background-position: -62px -43px;
}
.icon_skype{
		width: 16px;
		height: 17px;
	margin-right: 5px;
		background-position: -96px -43px;
}
.m-t-10{
	margin-top: 10px;
}
.line_left{
	float: left;
		border-left: 1px solid #ccc;
		padding-left: 8px;
}
.color_dark{
	color: #333!important;
}
.icon_phone {
		width: 40px;
		height: 40px;
		background-position: -202px 4px;
}
.icon_email {
		width: 40px;
		height: 40px;
		background-position: -262px 4px;
}
.support_header p{
	margin-bottom: 0;
}
input#filter_basic {
		display: inline-block;
		border-radius: 0;
		margin-right: -7px;
}
.btn_search{
	background-color:#0064b1;
		text-indent: -9999px;
		width: 34px;
		border: none;
		float: right;
		height: 30px;
		margin-right: 14px;
		border-radius: 0;
		background-image: url(img/icon.png);
		background-position: -164px -67px;
}
/* END HEADER */
/* MENU */
/* #nav_menu{ */
/* } */
/* .navbar-oneweb { */
/*     -webkit-background-color: #0366ff; */
/* 	-o-background-color: #0366ff; */
/* 	-moz-background-color: #0366ff; */
/* 	-ms-background-color: #0366ff; */
/* 	background-color: #0366ff; */
/* 	-webkit-box-shadow: 0 2px 3px #b4b4b4;; */
/* 	-o-box-shadow: 0 2px 3px #b4b4b4;; */
/* 	-moz-box-shadow: 0 2px 3px #b4b4b4;; */
/* 	-ms-box-shadow: 0 2px 3px #b4b4b4;; */
/* 	box-shadow: 0 2px 3px #b4b4b4;; */
/* } */
#nav_menu {
/*     -webkit-background-color: #0366ff; */
/* 	-o-background-color: #0366ff; */
/* 	-moz-background-color: #0366ff; */
/* 	-ms-background-color: #0366ff; */
/* 	background-color: #0366ff; */
/* 	-webkit-box-shadow: 0 2px 3px #b4b4b4;; */
/* 	-o-box-shadow: 0 2px 3px #b4b4b4;; */
/* 	-moz-box-shadow: 0 2px 3px #b4b4b4;; */
/* 	-ms-box-shadow: 0 2px 3px #b4b4b4;; */
/* 	box-shadow: 0 2px 3px #b4b4b4;; */
		z-index: 1;
	position: absolute;
	width: 100%;
}
.navbar{
	min-height:40px;
	border-radius: 0;
	margin-bottom: 0;
}
.navbar-nav>li:last-child{
	border-right: none;
}
.navbar-oneweb .navbar-toggle {
		border-color: white;
}

.nav>li>a:focus, .nav>li>a:hover {
		text-decoration: none;
	color: #fff;
}
.navbar-oneweb .navbar-nav>.active>a, .navbar-oneweb .navbar-nav>.active>a:focus, .navbar-oneweb .navbar-nav>.active>a:hover {
}
.navbar-nav.navbar-right li a {
		color: #ffe010;
	padding-left: 0;
}
.navbar-nav > .dropdown > .dropdown-menu > .dropdown >ul.dropdown-menu {
		left: 100%;
	top: 0;
}
.icon_home {
		height: 33px;
		width: 30px;
		background-position: 0 -40px;
}
.color_red{
	color: $price-new-color;
}
.color_pink{
	color: #e10a64!important;
}
.border_shadow {
		box-shadow: 0 0 4px #b2b2b2;
		border: 1px solid #ccc;
}
ol.breadcrumb {
	margin: 0;
	padding-left: 0;
		background-color: transparent;
}
.nav-tabs{
	border-bottom: none;
}
.product_des .tab_container {
	border-top: 2px solid $primary-color;

	.tab_content {
		border: 1px solid $divider-color;
		border-top: none;
	}
}
.nav-tabs>li>a{
	border-radius: 5px 5px 0 0;
	padding: 5px 15px;
	color: $text-color;
	border: 1px solid $primary-color;
	border-bottom: 0;
}
.nav-tabs>li>a:hover{
	background-color: $primary-color;
	border: 1px solid $primary-color;
	color: #fff;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
	background-color: $primary-color;
	border: 1px solid $primary-color;
	color: #fff;
}
/* END MENU */
/* HOME */
.shadow_bottom{
	position: relative;
		-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
	margin-bottom: 10px;
	background-color: #fff;
}
.shadow_bottom:after {
	content: "";
		position: absolute;
		z-index: 0;
		top: 100%;
		bottom: 0;
		width: 100%;
		height: 5px;
		left: 0%;
		right: -10%;
		background: -webkit-radial-gradient(50% -3%, ellipse cover, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 40%);
		background: radial-gradient(ellipse at 50% -3%, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 69%);
}
div#form_quick {
		margin-top: -110px;
		padding-bottom: 15px;
}
div#frm_quick_enquiry {
	border-radius: 5px;
		background: rgba(255,255,255,0.8);
}

.btn_contact{
	background-color: #ed9300;
	background-image: none;
	border: 1px solid #d98800;
	color: #fff;
	text-shadow: none;
}
.btn_submit{
	background-color: #4cb753;
}
.btn_submit {
		background-color: #4cb753;
		background-image: none;
		text-shadow: none;
		color: #fff;
		border: 1px solid #36853d;
}
.btn_submit:hover,btn_submit:focus,btn_submit:active:focus{
	background-color: #36853d;
	color: #fff;
}
.icon_calendar {
		width: 20px;
		height: 25px;
		background-position: -203px -43px;
		position: absolute;
		top: 8px;
		right: 12px;
}
#slideshow{
	min-height: 95px;
	position: relative;
}
#bg_slide{
	box-shadow: 2px 0 4px rgba(0,0,0,0.3);
}
#bg_slide:after {
		content: "";
		position: absolute;
		z-index: 0;
		top: 100%;
		bottom: 0;
		height: 7px;
		width: 76%;
		left: 12%;
		right: -10%;
		background: -webkit-radial-gradient(50% -3%, ellipse cover, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 40%);
		background: radial-gradient(ellipse at 50% -3%, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 69%);
}
.shadow_right {
		box-shadow: 2px 0 4px rgba(0,0,0,0.3);
}
.shadow_right:before{
		content: "";
		position: absolute;
		z-index: 0;
		top: -6px;
		bottom: 0;
		height: 5px;
		transform: rotate(180deg);
		width: 59%;
		left: 15%;
		right: -10%;
		background: -webkit-radial-gradient(50% -3%, ellipse cover, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 40%);
		background: radial-gradient(ellipse at 50% -3%, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 69%);
}
.shadow_top {
		box-shadow: 0 -2px 4px rgba(0,0,0,0.3);
}
#owl-travel-team img {
		border-radius: 100%;
}
.carousel-inner>.item>a>img {
		display: inline-block;
		width: 100%;
}
.carousel-indicators{
	display: none;
}
.search_tour .title{
	font-weight: bold;
}

.search_tour .btn_search {
		border: 2px solid #96adb4;
		background-color: #bacfd3;
		background-image: none;
		text-shadow: none;
		color: #666666;
}
.btn_readmore {
		color: #279836;
		border: 1px solid #279836;
		border-radius: 5px;
		padding: 6px 10px;
}
.text_color_blue{
	color: #3e9dd7;
}
.text_color_green{
	color: #26b200;
}
.line_title{
	border-bottom: 1px solid #ccc;
}
.btn_enquire{
	color: #279836;
		border: 1px solid #279836;
	background-color: transparent;
		border-radius: 5px;
		padding: 6px 10px;
}
.fix3row {
		height: 69px;
		overflow: hidden;
}
.fix2row {
		height: 37px;
		overflow: hidden;
	margin-top: 5px;
}
.title_block{
	font-size: 28px;
		text-shadow: 0 2px 3px #b2b2b2;
}
.icon_begin {
		height: 30px;
		background-position: -309px -6px;
}
.icon_finish {
		height: 84px;
		background-position: -365px 47px;
}
#testimonial{
	background: url('img/bg_testimonial.jpg') no-repeat top center;
	min-height: 235px;
}
#testimonial .thumb{
	background-color: #fff;
	padding: 8px;
}
#box_travel_team .customNavigation .btn{
	top:46%;
}
/* END HOME */
/*tab review-**/
#table_review_tour .col{
	border-bottom: 4px solid #fff;
}
#table_review_tour >.col.score_col{
	padding-top:6%;
}
.score_col {
		background-color: #e6edf6;
		border-right: 3px solid #fff;
		vertical-align: middle;
}
.score_detail_col {
		background-color: #e6edf6;
		border-right: 3px solid #fff;
		vertical-align: top;
}
.score_detail {
		vertical-align: top;
		background-color: #e6edf6;
}
.per_bar_cover {
		background-color: #fff;
		border: 1px solid #589442;
		height: 10px;
		width: 99%;
}
.per_bar {
		background-color: #589442;
		border: 0 none;
		float: left;
		height: 100%;
		width: 50%;
}
#filter_review #f_selection {
		background: #f1f4fa;
		float: left;
		width: 100%;
		border-bottom: 1px solid #ccc;
}
.btnFilter {
		background-color: #fff;
		border: 1px solid #006431;
		padding: 3px 10px 2px;
		display: inline-block;
}
.icon-remove {
	background-position: -322px 0;
		height: 11px;
		width: 11px;
		display: inline-block;;
}
.reviews > .review {
		float: left;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		margin: 0;
		max-height: 415px;
		overflow-x: hidden;
		overflow-y: scroll;
		padding: 0;
		width: 100%;
}
.reviews .review li {
		border-bottom: 1px solid #ccc;
		float: left;
		list-style: outside none none;
		padding: 5px;
		width: 100%;
}
.reviews .review li .auth {
		float: left;
		width: 150px;
		text-align: left;
		margin-right: 15px;
}
.reviews .review li .cmt {
		background: url("img/arrow3.png") no-repeat scroll left 10px rgba(0, 0, 0, 0);
		float: left;
		padding-left: 11px;
		font-style: italic;
		text-align: left;
		width: 70%;
}
.reviews .review li .score_col {
		background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
		border-right: 0 none;
		float: right;
		width: 7%;
}
#table_review_cruise .col {
		min-height: 125px;
}
.score_filter_text span {
		float: right;
}
 .reviews .review li .name {
		float: left;
		font-weight: bold;
		line-height: 15px;
		margin-bottom: 0;
		width: 100%;
		padding: 0;
 }
.score_col .totscore {
		background: url("img/comment_big_right.png") no-repeat scroll center center rgba(0, 0, 0, 0);
		color: #fff;
		font-size: 16px;
		font-weight: bold;
		display: inline-block;
		padding: 10px 0 14px;
		text-align: center;
		width: 100%;
}
.review_breakdown_block th {
		float: left;
		margin-left: 10px;
		text-align: left;
		width: 180px;
}
.review_breakdown_block td {
		padding: 0 7px;
		text-align: left;
}
.score_col > label {
		color: #054082;
		display: inline-block;
		font-size: 17px;
		font-weight: bold;
		text-align: center;
		width: 100%;
}

.review_highlight {
		color: #054082;
		font-weight: bold;
		margin-left: 10px;
}
.score_filter_text div {
		border-bottom: 1px dotted #999;
		margin-right: 20px;
		text-align: left;
}
div#filter_review {
		margin-top: -4px;
}
.review_filter_block td {
		padding: 3px 2px 3px 20px;
}
.review_filter_block {
		border-spacing: 0;
		empty-cells: show;
		font-size: 12px;
		width: 100%;
}
.headline_title {
		float: left;
		width: 3px;
		height: 13px;
		margin: 4px 3px 0 0;
		background-color: $second-color;
}
.download {
		background-position: -97px -68px;
		padding: 1px 7px;
	float: none;
		display: inline-block;
}
/* SIDEBAR */
#tree ul.nav li {
	display: inline-block;
		width: 100%;
		padding-left: 4px;
		border-bottom: 1px dotted #ccc;
}
.bg_gray{
	background-color: #eee;
}
.box_shawdow{
	-webkit-box-shadow: 0 3px 4px #b4b4b4;
	-o-box-shadow: 0 3px 4px #b4b4b4;
	-moz-box-shadow: 0 2px 4px #b4b4b4;
	-ms-box-shadow: 0 3px 4px #b4b4b4;
	box-shadow: 0 3px 4px #b4b4b4;
}
.block_title {
		border-bottom: 2px solid #006ebd;
		height: 35px;
		line-height: 35px;
		float: left;
	width: 100%;
		overflow: hidden;
}
.icon_s_title{
	width: 20px;
		height: 25px;
		margin-right: 2px;
		background-position: -298px -33px;
}

.icon_li{
	width: 17px;
	height: 25px;
	background-position: -296px -65px;
}

#tree .nav>li>a:focus{
	background: transparent;
}

.box .nav a{
	color: #333
}
.box .nav a:hover,.box .nav a:focus,.box .nav a:active:focus{
	color: $second-color;
}
.border_b_blue{
	border-bottom: 2px solid #006ebd;
		line-height: 35px;
		height: 35px;
}
/* END SIDEBAR */
/* TOUR DETAIL */
/* fancybox */
/*  Product View */
.product-view .product-img-box {
	margin-bottom: 10px;
	position: relative;
}
.product-view .product-img-box .product-image {
	margin: 0 0 13px;
}
/* Product Images */

/* Product Images */

#etalage .moreview_thumb {
	cursor: move;
}
#moreview .moreview_small_thumbs li {
	margin: 10px;
	margin-bottom: 13px !important;
	cursor: pointer;
}
#moreview .moreview_magnifier {
	border: 1px solid #bbb;
}
#moreview .moreview_hint {
	width: 130px;
	height: 57px;
}
#moreview .moreview_description {
	font-style: italic;
	margin: 10px;
	padding: 6px 10px;
}
.moreview, .moreview_thumb, .moreview_thumb_image, .moreview_source_image, .moreview_zoom_preview, .moreview_icon, .moreview_hint {
	display: none
}
.moreview, .moreview ul, .moreview li, .moreview img, .moreview_hint, .moreview_icon, .moreview_description {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none
}
.moreview, .moreview_magnifier div, .moreview_magnifier div img, .moreview_small_thumbs ul, ul .moreview_small_thumbs li, .moreview_zoom_area div, .moreview_zoom_img {
	position: relative
}
.moreview img, .moreview li {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-moz-user-drag: none;
	user-drag: none
}
.moreview, .moreview_small_thumbs li {
	float: left
}
.moreview_right {
	float: right
}
.moreview li {
	position: absolute
}
.moreview img {
	vertical-align: bottom;
	max-width: none
}
.moreview_magnifier {
	cursor: default
}
.moreview_magnifier div, .moreview_small_thumbs {
	overflow: hidden
}
.moreview_magnifier div img {
	display: none
}
.moreview_icon, .moreview_hint {
	cursor: default;
	width: 0;
	height: 0;
	overflow: hidden
}
.moreview_small_thumbs li.vertical {
	float: none
}
.moreview_zoom_area {
	z-index: 2
}
.moreview_zoom_area div {
	overflow: hidden;
	z-index: 997
}
.moreview_zoom_preview {
	position: absolute;
	z-index: 998
}
.moreview_zoom_img, .moreview_hint {
	z-index: 999
}
.moreview {
	direction: ltr
}
div.moreview_description {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 999
}
div.moreview_description.rtl {
	direction: rtl;
	text-align: right
}
.moreview-control a {
	left: auto;
	width: 26px;
	height: 26px;
	background-color: transparent;
}
.product-view .product-img-box .moreview-control {
	opacity: 0;
}
.product-view .product-img-box:hover .moreview-control {
	opacity: 1;
	transition: all 0.3s linear;
}
.moreview-control a.moreview-prev {
	position: absolute;
	left: 9.5%;
	background-color: #fff;
	border: 1px #ddd solid;
	text-align: center;
	color: #333;
}
.moreview-control a.moreview-prev:hover {
	background-color: #333;
	border: 1px #333 solid;
	color: #fff;
	transition: all 0.3s linear;
}
.moreview-control a.moreview-prev:before {
	content: "\f106";
	font-family: FontAwesome;
	font-size: 18px;
}
.moreview-control a.moreview-next {
	position: absolute;
	left: 9.5%;
	background-color: #fff;
	border: 1px #ddd solid;
	text-align: center;
	color: #333;
	bottom: 0%;
}
.moreview-control a.moreview-next:hover {
	background-color: #333;
	border: 1px #333 solid;
	color: #fff;
	transition: all 0.3s linear;
}
.moreview-control a.moreview-next:before {
	content: "\f107";
	font-family: FontAwesome;
	font-size: 18px;
}
.moreview .roll-over {
	position: absolute;
	bottom: 10px;
	left: 10px;
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	color: #888;
	text-indent: 20px;
}
.moreview .roll-over .roll-over-desc {
	margin-left: 20px;
}
#moreview .moreview_thumb, #moreview .moreview_small_thumb {
	border: solid 1px #e0e0e0;
}
.product-view .product-img-box .product-image {
	margin: 0 0 13px;
}
img.zoomImg {
		display: none!important;
}
/*** zoom popup ***/
.product-img-box .zoom{
	position: absolute;
		top: 0;
		right: 0;
		z-index: 10;
		display: block;
		width: 100%;
		height: 100%;
}
img.moreview_zoom_preview {
		opacity: 1!important;
}
.product-view .info .border_info{
	border: 1px solid #CCC;
	float: left;
	padding: 15px;
	width: 100%;
	border-radius: 6px;
		-webkit-box-shadow: inset 0px 0px 5px 3px rgba(204,204,204,0.8);
		-moz-box-shadow: inset 0px 0px 5px 3px rgba(204,204,204,0.8);
		box-shadow: inset 0px 0px 5px 3px rgba(204,204,204,0.8);
}
#box_product_slide .customNavigation .btn {
		top: 44%;
}
.customNavigation .prev {
	left: 22px;
}
.customNavigation .next{
	right: 22px;
}
.customNavigation .btn{
	background-color: #e2e2e2;
		top: 24%;
	position: absolute;
		width: 21px;
		padding: 0 3px;
		height: 23px;
		text-align: center;
		line-height: 23px;
		color: #fff;
}
#owl-product .item{
	display: inline-block;
}
.itinerary_day h3 {
	color: #0366ff;
	font-size: 16px;
		float: left;
		margin-right: 5px;
}
.status img {
		border: 1px solid #0366ff;
}
.show_map{
	width:100%;
	height: 500px
}
.product-view table tr td,.product-view table tr th {
/*     padding: 3px 0; */
		border-right: 1px solid #ccc;
}
/* END TOUR DETAIL */
/* TRIPPLAN */

h1.title_content {
		height: 40px;
		line-height: 40px;
}
.des_tripplan label{
	font-weight: normal;
}
/* END TRIPPLAN */
/* FOOTER */
.footer table,.footer table a{
}
div#footer_other {
}
.nav_footer a{
}
.nav_footer ul {
		list-style: none;
}
.nav_footer ul li{
	display: inline-block;
}
.nav_footer {
}
.nav_footer a {
		border-right: 1px solid;
		padding: 0 6px;
	color: #333;
	font-weight: bold;
}
.nav_footer a:last-child{
	border-right: none;
}
/* END FOOTER */
/* Large desktops and laptops */
@media (min-width: 1200px) {
/* .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9{ */
/* 		padding-left: 5px; */
/* 		padding-right: 5px; */
/* 	} */

/* 	.row{ */
/* 		margin-left: -5px;  */
/* 		margin-right: -5px; */
/* 	} */
.navbar-oneweb .navbar-collapse, .navbar-oneweb .navbar-form {
			padding: 0;
	}
	.search_tour{
		margin-top: -60px;
	}
	.search_tour .col-md-2.title{
		color: #fff;
			padding-left: 6%;
	}
	.search_tour .col-md-2 {
			padding-right: 0;
	}
	.search_tour input,.search_tour select {
			background: rgba(0,0,0,0.4);
		color: #fff;
	}
	.container {
	}
	#bg_slide{
	}
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
	.search_tour{
		margin-top: -60px;
	}
	.search_tour .col-md-2.title{
		color: #fff;
			padding-left: 4%;
	}
	.search_tour .col-md-2 {
			padding-right: 0;
	}
	.search_tour input,.search_tour select {
			background: rgba(0,0,0,0.4);
		color: #fff;
	}
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}
@media (min-width: 768px){
	.col-lg-5item {
			width: 20%;
	}
	div#mmenu {
			margin-top: 28px;
	}
	.navbar-nav>li>a {
		padding: 7px 0;
		margin: 0 9px;
	}
	.adv_block img.img-responsive {
			display: inline-block;
	}
}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
	.navbar-nav>li>a{
		color: #333;
		text-align: right;
	}
	.navbar-nav .open .dropdown-menu>li>a{
		text-align: right;
	}
}

/* Portrait phones and smaller */
@media (max-width: 480px) {

}
@media (max-width: 425px) {
	.thumb img{
		max-width: 100%;
	}
}
@media (max-width: 375px){

}